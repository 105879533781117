import jQuery from 'jquery';
import 'jquery-placeholder';
import 'jscrollpane';
import 'jquery-colorbox';
import 'flexslider';
import 'jquery-ui';
var $ = jQuery;

jQuery(function () {
    // $ = jQuery;
    $('input, textarea').placeholder();

    $('.jscrollpane').jScrollPane();

    $('.colorbox').colorbox({maxWidth: '95%', maxHeight: '95%'});

    $('a[href=\\#]').on('click', function(e){
        // url conditions d'utilisation
        if (!$(this).is('[href$="#cgu"]')) 
        {
            e.preventDefault();

            if (this.hash && this.hash.length > 1) {
                $('html, body').animate({
                    scrollTop: $(this.hash).offset().top - 80
                }, 500);
            }
        }
    });

    $('.block-action').click(function (e) {
        $(this).parent().find('.block-toggleable').toggleClass('active');
    })

    $('.toggle-hide').click(function (e) {
        e.preventDefault();
        $(this).toggle();
        $($(this).data('target')).toggle();
    })

    if ($(document).width() >= 1000) { //Desktop only
        var $fixexHeader = $('.desktop-fixed-header');

        $(window).scroll(debounce(function() {
            if ($(document).scrollTop() > 130) {
                $fixexHeader.addClass('active');
            } else {
                $fixexHeader.removeClass('active');
            }
        }, 10));

        if ($(document).scrollTop() > 130) {
            $fixexHeader.addClass('active');
        } else {
            $fixexHeader.removeClass('active');
        }

        //Main menu toggle sub
        var mainMenuTimeout;
        $('.main-menu .toggle').hover(function () {
            if (mainMenuTimeout) {
                clearTimeout(mainMenuTimeout);
            }

            if (!$(this).hasClass('active')) {
                $('.main-menu .toggle').not(this).removeClass('active');
                $(this).toggleClass('active');
                $('.main-menu-toggle').not($(this).data('target')).removeClass('active');
                $($(this).data('target')).toggleClass('active');
            }
        }, function () {
            var $this = $(this);
            mainMenuTimeout = setTimeout(function () {
                $this.removeClass('active');
                $($this.data('target')).removeClass('active');
            }, 50)
        }).click(function(){
            if (isMobile === false) {
                if ($(this).attr('data-target') === '.main-menu-toggle-4') {
                    window.open($(this).attr('href'));
                } else {
                    document.location.href = $(this).attr('href');
                }
            }
            return false;
        });

        $('.main-menu-toggle').hover(function () {
            if (mainMenuTimeout) {
                clearTimeout(mainMenuTimeout);
            }
        }, function () {
            mainMenuTimeout = setTimeout(function () {
                $('.main-menu .toggle').removeClass('active');
                $('.main-menu-toggle').removeClass('active');
            }, 50)
        })
    } else {
        var $fixexHeader = $('.top-bar-tablet');
        var lastScrollLeft = 0;

        $(window).scroll(debounce(function() {
            var documentScrollLeft = $(document).scrollLeft();

            if (lastScrollLeft !== documentScrollLeft) {
                lastScrollLeft = documentScrollLeft;
                $fixexHeader.css('left', -documentScrollLeft);
            } else {
            }
        }, 10));

        $fixexHeader.css('left', -$(document).scrollLeft());
    }

    //Boutique page slider
    var boutiqueSlider = $('.boutique-slider').flexslider({
        animation: "slide",
        easing: "easeInOutCirc",
        pauseOnHover: true,
        useCSS: false,
        controlNav: false,
        directionNav: false,
        slideshow: false,
        init: function(slider){
            $('.boutique-slider').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
          }
    }).data('flexslider');

    $('.offer-area .nav .slides li').click(function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.parents('.nav').find('li').removeClass('active');
        $this.addClass('active');
        boutiqueSlider.flexAnimate($this.data('target'));
    })

    if ($(document).width() <= 480) {
        $('.mobile-slider-full').flexslider({
            animation: "slide",
            easing: "easeInOutCirc",
            pauseOnHover: true,
            useCSS: false,
            directionNav: false,
            slideshowSpeed: 5000,
            init: function(slider){
                $('.mobile-slider-full').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
            }
            });

        $('.mobile-slider-full-nav').flexslider({
            animation: "slide",
            easing: "easeInOutCirc",
            pauseOnHover: true,
            useCSS: false,
            slideshowSpeed: 5000,
            before: function (slider) {
                boutiqueSlider.flexAnimate(slider.animatingTo);
            },
            init: function(slider){
                $('.mobile-slider-full-nav').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
            }
        });
    }

    //general slider
    $('.slider-full').flexslider({
        animation: "slide",
        easing: "easeInOutCirc",
        pauseOnHover: true,
        useCSS: false,
        slideshowSpeed: 5000,
        init: function(slider){
            $('.slider-full').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
        }
        });

    //Home big slider
    $('.home-slider').flexslider({
        pauseOnHover: true,
        useCSS: false,
        slideshowSpeed: 5000,
        animationSpeed: 0,
        init: function(slider){
            $('.home-slider').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
          }
    });

    //Search bar
    $('.search-bar .search-bar-toggle, .search-criteria .close').click(function (e) {
        e.preventDefault();
        $('.search-criteria').slideToggle().toggleClass('active');
        $('.search-bar .search-bar-toggle').toggleClass('active');
    })

    if ($(document).width() <= 480) {
        $('.search-criteria').toggle().toggleClass('active');
        $('.search-bar .search-bar-toggle').toggleClass('active');
    }

    //Home region slider
    $('.region-slider').flexslider({
        animation: "slide",
        easing: "easeInOutCirc",
        pauseOnHover: true,
        useCSS: false,
        slideshowSpeed: 5000,
        controlNav: false,
        itemWidth: 90,
        itemMargin: 0,
        init: function(slider){
            $('.region-slider').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
        }
        });

    //Home selection slider
    $('.selection-slider').flexslider({
        animation: "slide",
        easing: "easeInOutCirc",
        pauseOnHover: true,
        useCSS: false,
        slideshowSpeed: 5000,
        itemWidth: 313,
        itemMargin: 0,
        init: function(slider){
            $('.selection-slider').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
        }
        });

    //Toggle panel
    $('.toggle').click(function (e) {
        e.preventDefault();
        $('.toggle').not(this).removeClass('active');
        $(this).toggleClass('active');
        $('.toggleable').not($(this).data('target')).removeClass('active');
        $($(this).data('target')).toggleClass('active');
    })

    //Toggle panel
    $('.toggle-single').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $($(this).data('target')).toggleClass('active');
    })

    //Toggle dropdown
    $('.toggle-dropdown').click(function (e) {
        e.preventDefault();
        $('.toggle-dropdown').not(this).removeClass('active');
        $(this).toggleClass('active');
        $('.dropdownable').not($(this).data('target')).slideUp('fast');
        $($(this).data('target')).slideToggle('fast');
    })

    //Close panel
    $('.close-btn').click(function (e) {
        e.preventDefault();
        $($(this).data('target')).hide();
    })

    $('.search-area .search-popup a').click(function (e) {
        e.preventDefault();
        $(this).parents('.item').find('.text').text($(this).text()).end().find('.current').click();
    })

    //Toggle menu phone
    $('.top-bar-tablet .nav-menu').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.main-menu').toggleClass('active');
        $('.overlay-main-menu').toggleClass('active');
        $('.toggle, .toggleable').removeClass('active');

        if ($('.main-menu').is(':visible')) {
            $("html, body").animate({
                scrollTop: 0
            }, 'fast')
        }
    })
    $('.overlay-main-menu').click(function (e) {
        e.preventDefault();
        $(this).removeClass('active');
        $('.top-bar-tablet .nav-menu').removeClass('active');
        $('.main-menu').removeClass('active');
        $('.toggleable').removeClass('active');
    })

    //Mobile footer menu toggle
    $('.main-footer .nav .title').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active');
    })

    $('.hidden').hide();
    $('.filter-content-wrapper input[type="checkbox"]').each(function (e) {
        if($(this).is(':checked')){
            $(this).parents('.filter-content-wrapper').show();
            $(this).parents('.filter-content-wrapper').parent().addClass('active');

        }
    });
    //Searc sidebar block toggle, plier par defaut
    $('.search-sidebar .filter-title').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active').find('.filter-content-wrapper').stop().slideToggle('fast');
    });

    //Searc sidebar block togg, deplier par defaut, cas par  type de resultat
    $('.search-sidebar .filter-title-type', '.deplied .filter-title-type').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active').find('.filter-content-wrapper').stop().slideToggle('fast');
    });

    //Searc sidebar block togg, deplier par defaut, cas par  type de resultat
    $('.search-sidebar .filter-title-type', '.deplied .filter-title-type').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('active').find('.filter-content-wrapper').stop().slideToggle('fast');
    });

    //Result price slider
    var $priceSlider = $('.price-slider');
    if ($priceSlider) {
        var priceMap = ['- de 5', 5, 8, 11, 15, 20, 30, 50, 75, 100, '+ de 100'];
        var $min = $priceSlider.parent().find('.min');
        var $max = $priceSlider.parent().find('.max');

        $priceSlider.siblings('.slider-header').find('.value').each(function (i, el) {
            var left = 100 / (priceMap.length - 1) * i;
            $(el).css('left', left + '%');
        })

        // $priceSlider.slider({
        //     range: true,
        //     values: [ 1, 2 ],
        //     min: 0,
        //     max: priceMap.length - 1,
        //     slide: function( event, ui ) {
        //         $min.text( priceMap[ui.values[0]]); //map selected "value" with lookup array
        //         $max.text( priceMap[ui.values[1]]); //map selected "value" with lookup array
        //     }
        // });
        // $min.text(priceMap[$priceSlider.slider("values", 0)]);
        // $max.text(priceMap[$priceSlider.slider("values", 1)]);
    }

    //Tab
    $('.tab-area .nav li').click(function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.parents('.nav').find('li').removeClass('active');
        $this.addClass('active');
        $this.parents('.tab-area').find('.tab-content').removeClass('active');
        $this.parents('.tab-area').find($this.data('target')).addClass('active');
    })
    $('.tab-area .nav-phone select').change(function (e) {
        e.preventDefault();
        var $this = $(this);

        $this.parents('.tab-area').find('.tab-content').removeClass('active');
        $this.parents('.tab-area').find($this.val()).addClass('active');
    })

    //Comment rating
    let $commentStars;
    $commentStars = $('.comment-form .star');
    $commentStars.each(function (idx, el) {
        $(el).hover(function () {
            for (var i = 0; i <= idx; i++) {
                $commentStars.eq(i).addClass('hover');
            }
        }, function () {
            $commentStars.removeClass('hover');
        });

        $(el).click(function (e) {
            e.preventDefault();
            $commentStars.removeClass('active');

            for (var i = 0; i <= idx; i++) {
                $commentStars.eq(i).addClass('active');
            }
        })
    })

    //Page article area slider
    $('.article-area .slider-image a').click(function (e) {
        e.preventDefault();

        $('.article-area .main-image img').attr('src', $(this).attr('href'));
    })

    if ($(document).width() > 480) {
        setTimeout(function () {
            $('.article-area .slider-image').flexslider({
                animation: "slide",
                pauseOnHover: true,
                direction: "vertical",
                useCSS: false,
                controlNav: false,
                slideshowSpeed: 5000,
                init: function(slider){
                    $('.article-area .slider-image').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
                }
                });
        }, 1000);
    } else {
        $('.article-area .slider-image a').unwrap().wrap('<li></li>');

        $('.article-area .slider-image').flexslider({
            animation: "slide",
            pauseOnHover: true,
            useCSS: false,
            controlNav: false,
            slideshowSpeed: 5000,
            itemWidth: 55,
            itemMargin: 5,
            init: function(slider){
                $('.article-area .slider-image').css('height', 'auto').css('opacity', '100').css('overflow-y','initial');
            }
            });
    }

    //Toggle service tab of premium page
    $('.premium-service-toggle').click(function (e) {
        e.preventDefault();

        $('.tab-area li').eq(4).click();
        $('html, body').animate({
            scrollTop: $('.tab-area').offset().top - 70
        }, 500);
    })

    //Toggle domain area items
    $('.domain-toggle').click(function (e) {
        e.preventDefault();
        $('.domain-toggleable').removeClass('active');
        $($(this).data('target')).addClass('active');
    })

    //Hover
    $('.hoverable').hover(function () {
        $(this).find('.hovered').show();
    }, function () {
        $(this).find('.hovered').hide();
    })

    //Boutique page featured image
    $('.boutique-area .featured-image .item').click(function (e) {
        e.preventDefault();
        $('.boutique-area .featured-image .image').removeClass('active');
        $($(this).data('target')).addClass('active');
    })

    //Espace tab item click
    $('.club-area .item').click(function (e) {
        var index = ($(this).index() < 3) ? $(this).index() + 1 : $(this).index();

        $('.tab-area .nav li').eq(index).click();
        $('.nav-phone option').eq(index).attr('selected', 'selected');
    })
    
    $('#PopupCreateAccount').on("click", function (){
        setDataLayerPushForPopupCreateAccount();
    });
});

function debounce (func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

//----------------------------------------------------------------------------------------------------
// CHECKBOX & RADIO
//----------------------------------------------------------------------------------------------------
function checkbox_radio() {
    $(document).on('click', '.useCheckbox label', function() {
        var $use = $(this).parent('.useCheckbox');
        if ($use.hasClass('active'))
            $use.removeClass('active');
        else
            $use.removeClass('active').addClass('active');
    });
    $(document).on('click', '.useRadio label', function() {
        var $use = $(this).parent('.useRadio');
        var name = $use.find('input[type="radio"]').attr('name');
        $('.useRadio input[name="' + name + '"]').parent('.useRadio').removeClass('active');
        $use.removeClass('active').addClass('active');
    });
}
//----------------------------------------------------------------------------------------------------
// DISPLAY POPIN
//----------------------------------------------------------------------------------------------------
function displayPopin(form, popin) {
    if ( form !== false ) {
        $('#'+form+' .form-row.required').each(function() {
            var $this        = $(this);
            var error        = $this.find('.messageError').val();
            var input        = $this.find('input');
            if ( input.val() === '' || ( input.is(':radio, :checkbox') && !input.is(':checked') ) ) {
                $this.removeClass('error').addClass('error');
                $this.append('<div class="hint"><img src="img/icon-error-red.png" alt="!"> '+error+'</div>');
                return false;
            } else {
                $this.removeClass('error');
                $('.form-row .hint').remove();
            }
        });
        if ( $('#'+form+' .form-row.required .hint').length > 0 ) {
            setTimeout(function() { $('.form-row .hint').remove(); }, 2000);
            return false;
        }
    }
    $('body').removeClass('modal-open').addClass('modal-open');
    $('.overlay-box .inner').removeClass('hide').addClass('hide');
    $('.overlay-box').removeClass('hide');
    $('#'+popin).removeClass('hide');

    return false;
}
//----------------------------------------------------------------------------------------------------
// CLOSE POPIN
//----------------------------------------------------------------------------------------------------
function closePopin() {
    $('body').removeClass('modal-open');
    $('.overlay-box .inner').removeClass('hide').addClass('hide');
    $('.overlay-box .inner .required').removeClass('error');
    $('.overlay-box .inner .required .hint').remove();
    $('.overlay-box .inner .form-row input:not(.messageError)').val('');
    $('.overlay-box').removeClass('hide').addClass('hide');
}

//----------------------------------------------------------------------------------------------------
// SHOW PHONE | HIDE DESKTOP
//----------------------------------------------------------------------------------------------------
function SearchCriteria() {
    if ( $(window).width() <= 480 ) {
        $('.search-bar-toggle, .search-criteria').removeClass('active');
        $('.search-criteria').hide();
    } else {
        $('.search-bar-toggle, .search-criteria').removeClass('active').addClass('active');
        $('.search-criteria').show();
    }
}
//$(window).resize(SearchCriteria);

//----------------------------------------------------------------------------------------------------
//GOOGLE TAG MANAGER
//----------------------------------------------------------------------------------------------------
export {setDataLayerPushForNewsletter}
function setDataLayerPushForNewsletter(zone){
    var textZone = '';
    switch (parseInt(zone)) {
        case 1:
            textZone = 'popup header';
            break;
        case 2:
            textZone = 'popup footer';
            break;
        case 3:
            textZone = 'checkbox création de compte';
            break;
        case 4:
            textZone = 'encart colonne droite page';
            break;
        default:
            textZone = 'popup header';
            break;

    }

    dataLayer.push(
        {
            'event' : 'newsletter',
            'zone'  : textZone
        });
}

function setDataLayerPushForPopupCreateAccount() {
    dataLayer.push(
        {
            'event' : 'creationDeCompteAffichagePopup',
            'page'  : '/vpv/popup-formulaire-creation-de-compte'
        });
}


function setDataLayerPushCheckout(etape, abo, userConnected)
{
    var pageName = "";
    var abo = (abo === "millesime") ? 2 : ((abo === "privilege") ? 3 : abo) ;

    switch (etape)
    {
        case 1 :
            pageName = "/vpv/checkout-etape-1-votre-offre";
            break;
        case 2 :
            if (abo === 2) //millesime
            {
                if (userConnected)
                {
                    pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-identifie/abo-millesime";
                } else {
                    pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-non-identifie/abo-millesime";
                }
            } 

            if (abo === 3) // privilege (papier)
            {
                if (userConnected)
                {
                    pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-identifie/abo-privilege";
                } else {
                    pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-non-identifie/abo-privilege";
                }
            }

            if (abo === 8) // premium gratuit
            {
                if (userConnected)
                {
                    pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-identifie/abo-premium";
                } else {
                    pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-non-identifie/abo-premium";
                }
            }
            break;
        case 3 :
            pageName = "/vpv/checkout-etape-3-recapitulatif-commande";
            break;

    }
    dataLayer.push(
        {
            'event' : 'checkout',
            'page'  : pageName
        });

}
window.setDataLayerPushCheckout = setDataLayerPushCheckout;

function changeOpenPage(new_url, isTheSameSite)
{
    if (isTheSameSite) {
        window.location.replace(new_url); 
    } else {
        window.open(new_url);
    }
}
window.changeOpenPage = changeOpenPage;

function setDataLayerAcheterVin(zone, nomeProduit, sousTitreProduit, urlProduit)
{
    dataLayer.push({
        'event' : 'LienPartenaireAcheterCeVin',
        'zone'  : zone,
        'nomProduit' : nomeProduit,
        'sousTitreProduit' : sousTitreProduit,
        'urlProduit' : urlProduit
    });
}
window.setDataLayerAcheterVin = setDataLayerAcheterVin;

/********************* TAG GOOLGE MANAGER ******************/

//event creation de compte
function setDataLayerCreateCompte(zone, abo, userConnected)
{
//split date
    var data = $("#sonata_user_registration_form_dateOfBirth").val();
    var dayOfBirth = data.split("/");

    //guideHachette
    var guideHachetteVal = "";
    if ($("#sonata_user_registration_form_hasGuideHachette_1").is(':checked'))
    {
        guideHachetteVal = $("#sonata_user_registration_form_hasGuideHachette_1").val();
    }
    else if ($("#sonata_user_registration_form_hasGuideHachette_0").is(':checked'))
    {
        guideHachetteVal = $("#sonata_user_registration_form_hasGuideHachette_0").val();
    }

    //civilite
    var oResCivilite = "";
    if ($("#sonata_user_registration_form_civility_0").is(':checked'))
    {
        oResCivilite = "Mr";
    }
    else if ($("#sonata_user_registration_form_civility_1").is(':checked'))
    {
        oResCivilite = "Mme";
    }
    else if ($("#sonata_user_registration_form_civility_2").is(':checked'))
    {
        oResCivilite = "Mlle";
    }

    //var civilite =
    var codePostal        = $("#sonata_user_registration_form_postalCode").val();
    var ville             = $("#sonata_user_registration_form_city").val();
    var pays              = $("#sonata_user_registration_form_country").val();
    var anneeNaissance    = dayOfBirth[2];
    var type              = ($("#sonata_user_registration_form_accountType_0").is(':checked')) ? "personnel" : "professionnel " ;
    var guideHachette     = guideHachetteVal;
    var newsletter        = ($("#sonata_user_registration_form_newsletter").is(':checked')) ? "oui" : "non";
    var ventesPrivees     = ($("#sonata_user_registration_form_has1Jour1Vin").is(':checked')) ? "oui" : "non";
    var offresPartenaires = ($("#sonata_user_registration_form_optins").is(':checked')) ? "oui" : "non";
    var pageName = '/vpv/popup-formulaire-creation-de-compte';

    switch (zone)
    {
        case 1 : // popup via bouton "connexion"
            pageName = "/vpv/popup-formulaire-creation-de-compte";
            break;
        case 2 : // abonnement
            if (abo === 2) //millesime
            {
                pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-non-identifie/abo-millesime";
            } 

            if (abo === 3) // privilege (papier)
            {
                pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-non-identifie/abo-privilege";
            }

            if (abo === 8) // premium gratuit
            {
                pageName = "/vpv/checkout-etape-2-vos-coordonnees/utilisateur-non-identifie/abo-premium";
            }
            break;
    }

    dataLayer.push({
        'event'             : 'creationDeCompte',
        'civilite'          : oResCivilite,
        'codePostal'        : codePostal,
        'ville'             : ville,
        'pays'              : pays,
        'anneeNaissance'    : anneeNaissance,
        'type'              : type,
        'guideHachette'     : guideHachette,
        'newsletter'        : newsletter,
        'ventesPrivees'     : ventesPrivees,
        'offresPartenaires' : offresPartenaires,
        'page'              : pageName
    });
}
export { setDataLayerCreateCompte }
export { setDataLayerIdentication }
function setDataLayerIdentication() {
    dataLayer.push({
        'event' : 'identification',
        'type'  : 'connexion'
    });
}

function setDataLayerContact(propos, objet, caractere) {
    dataLayer.push ({
        'event'     : 'contact',
        'propos'    : propos,
        'objet'     : objet,
        'caractere' : caractere
    });
}

function setDataLayerMoteurDeRecherche(motCle, nombreResultatsRecherche) {
    dataLayer.push({
        'event'  : 'moteurDeRecherche',
        'motCle' : motCle,
        'nombreResultatsRecherche' : nombreResultatsRecherche
    });
}
window.setDataLayerMoteurDeRecherche=setDataLayerMoteurDeRecherche;

function setDataLayerPurchase(idtrans, revenue, productName, productId, productPrice) {
    dataLayer.push({
        'event'    : 'purchase',
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': idtrans,
                    'affiliation': 'hachette vins',
                    'revenue': revenue,
                    'tax': '0.82',
                    'shipping': '',
                    'coupon': ''
                },
                'products': [{
                    'name': productName,
                    'id': productId,
                    'price': productPrice,
                    'brand': 'hachette vins',
                    'category': 'abonnement',
                    'variant': '',
                    'quantity': 1,
                    'coupon': ''
                }]
            }
        }
    });
}
window.setDataLayerPurchase=setDataLayerPurchase;

function setDataLayerTrouverUnVin(couleur, region, prix, note, coupDeCoeur, achatEnLigne, nombreResultatsRecherche, nombreCriteres) {
    dataLayer.push({
        'event'  :'trouverUnVin',
        'couleur': couleur,
        'region' : region,
        'prix'   : prix,
        'note'   : note,
        'coupDeCoeur'  : coupDeCoeur,
        'achatEnLigne' : achatEnLigne,
        'nombreResultatsRecherche' : nombreResultatsRecherche,
        'nombreCriteres'           : nombreCriteres
    });
}
window.setDataLayerTrouverUnVin=setDataLayerTrouverUnVin;
/**************** END TAG GOOLGE MANAGER ******************/

/*(function (window) {

  'use strict'; // if you say so Mr. Crockford.

  // In case the dataLayer_debugger obj does not exist, make it.

  if (window.dataLayer_debugger === undefined) {

      // Set the value of the dataLayer_debugger variable
      // to the return value of an immediately evoked function.

      window.dataLayer_debugger = (function(){

      // Instantiate the main dldb object to be returned as mentioned above

      var dldb = {

        // Cache the dataLayer array.

        "dlCache" : window.dataLayer,

        // Cache the default dataLayer.push method.

        "pushCache" : window.dataLayer.push,

        // Acts as the on/of switch for on,off methods or book marklet.

        "keepState" : false,

        // Counts how many pushes occur.

        "pushCount" : window.dataLayer.length || 0,

        // time starts when GTM rule is fired or bookmarklet click

        "startTime" : new Date(),

        // to hold array of callback functions to call inside new .push method

        "callbacks" : [],

        // Does this debugger off cool logging features?

        "coolConsole" : navigator.userAgent.toLowerCase().match(/chrome|firefox/) ? true : false,

        // An object that holds all the current dataLayer values

        "current" : google_tag_manager.dataLayer

      };

      // Append methods to dldb object:

      // Returns time elapsed from startTime.
      // Used for timestamping dataLayer.push logs.

      dldb.now = function () {

        var now =  (new Date() - dldb.startTime) / 1000;

        return now;
      };

      // Returns whether the debugger is on or off.

      dldb.state = function () {

        var state = window.dataLayer_debugger.keepState ? "On" : "Off";

        return state;
      };


      // Turns the debugger on by changing the keepState variable,
      // changes the dataLayer.push method to add debugging functionality

      dldb.on = function () {

        dldb.keepState = true;
        window.dataLayer.push = window.dataLayer_debugger.push;

        console.log ("The dataLayer debugger is On");
        console.log ("Time is: " + window.dataLayer_debugger.now() + " To reset timer: dataLayer_debugger.reset('time')");

        for (var d = 0; d < window.dataLayer.length; d++){

          var obj = window.dataLayer[d],
          keys = Object.keys(obj);

          for (var k = 0; k < keys.length; k++){

            var key = keys[k],
            val = obj[key];

            // Set the new value of current

            //dldb.current[key] = val;
          }
        }

        window.dataLayer_debugger.log(window.dataLayer_debugger.current,"current dataLayer");

      };

      // Turns debugger off,
      // changes dataLayer.push method back to normal.

      dldb.off = function () {

        dldb.keepState = false;

        window.dataLayer = dldb.dlCache;
        window.dataLayer.push = dldb.pushCache;

        console.log("The dataLayer debugger is " + window.dataLayer_debugger.state() + ".");
        console.log("Current time is: " + window.dataLayer_debugger.now());
      };

      // Set one or many callback functions
      // to the debugging version of dataLayer.push method.

      dldb.setCallback = function (callback){

        window.dataLayer_debugger.callbacks.push(callback);
      };

      // Resets the timer, counter, and/or callbacks depending on arguments.
      // No arguments resets all- essentially the same as page refresh.

      dldb.reset = function () {

        for (var r = 0; r < arguments.length; r++){

          var arg = arguments[r];

          if (arg === "time") {

            dldb.startTime = new Date();

          }  if (arg === "count") {

            dldb.pushCount = 0;

          } else if (arg === "callbacks") {

            dldb.callbacks = [];

          } else {

            dldb.startTime = new Date();
            dldb.pushCount = 0;
            dldb.callbacks = [];

          }
        }
      };

      // Redefines the dataLayer.push method to add debugging functionality,
      // calls (applys) all the functions in the dldb.callbacks array,
      // calls the original dataLayer.push methon on all function arguments.

      dldb.push = function () {

        for (var a = 0; a < arguments.length; a++) {

          var obj = arguments[a];


          dldb.pushCount += 1;

          console.group( 'dataLayer.push: #' + dldb.pushCount + ", Time: " + window.dataLayer_debugger.now());

          window.dataLayer_debugger.log(obj,window.dataLayer_debugger.pushCount);

          console.groupEnd();
          console.dir(window.dataLayer);

          window.dataLayer_debugger.validate(obj, ['transactionTotal','transactionId'], ['transactionAffiliation', 'transactionShipping', 'transactionTax', 'transactionProducts'], "transaction");
          window.dataLayer_debugger.validate(obj, ['network','socialAction'],[], "social");

          // Call all callbacks within the context of the pushed object.

          if (window.dataLayer_debugger.callbacks){

            var callbacks = window.dataLayer_debugger.callbacks;

            for (var j = 0; j < callbacks.length; j++) {

              var callback = callbacks[j];

              callback.apply(obj);

            }
          }

          // Calls original cached version of dataLayer.push.

          dldb.pushCache(obj);
        }
      };

      // Pretty-logs an object's contents.

      dldb.log = function (object, optName){

        var ks = Object.keys(object).sort();

        for (var v = 0; v < ks.length; v++){

          if (ks[v] === 'event'){

            ks.unshift(ks.splice(v,1)[0]);
          }
        }

        console.group("object: " + (optName || ""));

        // Check for "event" property.
        // Put "event" property of pushed object first in list.

        try {

          for (var i = 0; i< ks.length; i++) {

            var key = ks[i],
            val = object[key],
            space = 25 - key.length;


            var logMessage = key + new Array(space).join(' ') + ': ';

            if (window.dataLayer_debugger.coolConsole) {

              var valType = (typeof(val) === 'object') ? '%O' : '%s';

              console.log( logMessage + valType, val);

            } else {

              console.log( logMessage + (typeof(val) !== 'object' ? val : "") );

              if (typeof(val) === 'object') {

                console.dir(val);

              }
            }

            // Set the new value of current

            dldb.current[key] = val;
          }
        }

        catch (e) {

          console.log("dataLayer error: " + e.message);

          if (window.dataLayer_debugger.coolConsole) {

            console.log("object was %O", object);

          } else {

            console.log( "object was:" );
            console.dir(val);
          }
        }
        console.groupEnd();
      };

      // Validates an object against an array mandatory valid key
      // and a second optional array of optional keys.
      // The last argument is the is a string name of the type of objec. ie 'social'

      dldb.validate = function (testObj, validKeys, optKeys, type) {

        var checked,
        validKey,
        optKey,
        checkedKeys = [],
        checks = validKeys.length > optKeys.length ? validKeys : optKeys;


        for (var j = 0; j < checks; j++) {

          validKey = validKeys[j];

          if (testObj[validKey]){

            checked = validKeys.splice(j,1);

            checkedKeys.push(checked);
          }

          else if (optKeys && testObj[optKey]) {

            optKey = optKey[j];

            checked = optKeys.splice(j,1);

            checkedKeys.push(checked);

            if (optKey === "transactionProducts" ) {

              var products = testObj.transactionProducts;

              for (var p = 0; p < products.length; p++) {

                var product = products[p];

                window.dataLayer_debugger.validate(product, ["name","sku","price","quantity"],  "product");

              }
            }
          }
        }

        if (validKeys.length) {

          console.log("Invalid " + (type ? type + " " : "") + "object pushed to dataLayer. Missing: " + validKeys.join(", "));

          return false;

        } else if (optKeys.length) {

          console.log("Valid " + (type ? type + " " : "") + "object pushed to dataLayer. Optional keys not used: " + validKeys.join(", "));
        }

        return true;
      };

      // End method definitions and return the created object.

      return dldb;

    })();

  }

  // Logic responsible for turning dataLayer_debugger on/off.

  if (!window.dataLayer_debugger.keepState) {

    window.dataLayer_debugger.on();

  }
  else {

    window.dataLayer_debugger.off();
  }

}(window));*/